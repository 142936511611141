<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Pesquisar transação</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <svg aria-hidden="true" style="width: 15px;" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-xmark fa-xl"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" class=""></path></svg>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-sm-9">
              <div class="input-group mb-2">
                <button class="input-group-prepend input-search btn-search-prod pointer" @click="request">
                  <i class="fa fa-search m-auto"></i>
                </button>
                <input type="text" class="form-control ng-pristine ng-untouched ng-valid ng-empty" name="search" v-model="search" placeholder="Pesquisar...">
              </div>
            </div>
            <div class="col-sm-3">
              <date-range-picker
                class="form-control mr-sm-2"
                value
                :options="optionsDate"
                @change="changeDate"
              />
            </div>
            <div class="col-sm-12" style="margin-top: 5px;">
              <input type="radio" name="type" v-model="type" value="income" class="ng-pristine ng-untouched ng-valid ng-not-empty"> Receita 
              <input type="radio" name="type" v-model="type" value="expense" class="ng-pristine ng-untouched ng-valid ng-not-empty"> Despesa
              <hr>
            </div>
          </div>

          <div class="row-prods-list relative">
            <div class="loading-overlay-inside" v-if="isSending" style="background-color:rgba(298, 294, 290, 0.9);">
              <div class="loader-box">
                <div class="circle-loader" style="top: 10%;"></div>
              </div>
            </div>
            <div class="row-prods-container" v-if="transactions.length == 0">
              <div class="text-center no-search-itens no-search-itens-p">
                <img src="/assets/images/bank_transaction.svg" class="nothing-image" style="width:20%">
                <h3 class="nothing-text info-search-prod">Use o campo acima para buscar lançamentos.</h3>
              </div>
              <ul class="list-group list-itens-prod text-left"></ul>
            </div>
            <h3 class="display-4" v-if="transactions.length > 0">Transações</h3>
            <hr v-if="transactions.length > 0">
            <ul class="list-group" v-if="transactions">
              <li class="list-group-item ng-scope item-search-select" v-for="(item, i) in transactions" :key="i" @click="changeItem(item)">
                <div class="row">
                  <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                    <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                    <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                  </div>
                  <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                    <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                    <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                  </div>
                  <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2 col-xs-2">
                    <strong class="ng-binding" v-if="item.account">{{item.account.name }}</strong>
                    <strong class="ng-binding" v-if="item.card">{{item.card.name }}</strong>
                  </div>
                  <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-2">
                    <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
                      <div class="icon-category-overview" :style="`margin-right:0px;background: ${item.category.color}`">
                        <img :src="`/assets/images/icons/png/${item.category.icon}.png`" />
                      </div>
                      &nbsp;{{item.category.name}}
                    </span>
                  </div>
                  <div class="col-sm-3 col-sm-3 col-lg-3 col-xs-3 text-right">
                    <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}&nbsp;&nbsp;</span>
                  </div>
                  <div class="col-sm-12" v-if="item.description">
                    <hr class="hr-transactions">
                    <span class="ng-binding transaction-note">{{item.description }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from '@/mixins/Modal';
import AppService from '@/services/app.service';
import moment from 'moment-timezone';
import {ptBR} from 'vuejs-datepicker/dist/locale'
import pace from 'pace-js';

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
  },
  data() {
    return {
      ptBR: ptBR,
      invoices: [],
      categories: [],
      cards: [],
      date: null,
      errors: [],
      lang: {
        formatLocale: {
          // MMMM
          months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          // MMM
          monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Otu', 'Nov', 'Dez'],
          // dddd
          weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qua', 'Qui', 'Sex', 'Sa'],
        },
        yearFormat: 'YYYY',
        monthBeforeYear: true,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      form: {
      },
      optionsDate: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 360,
        },
        locale: {
          format: 'DD/MM/YYYY',
          cancelLabel: 'Cancelar',
          // applyLabel: 'Filtrar',
          applyLabel: 'Ok',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      isSending: false,
      accounts: [],
      transactions: [],
      from: '',
      to: '',
      search: null,
      type: 'income',
    };
  },
  created() {
    this.form.date = new Date();
    this.$forceUpdate();

    this.from = `${moment(new Date()).format('YYYY-MM')}-01`;
    this.to = moment(this.from, "YYYY-MM-DD").endOf('month');
  },
  methods: {
    changeItem(item) {
      this.$root.$emit('transaction.select', {index: this.form.index, item: item});
      this.hide();
    },
    request() {
      pace.start();
      this.isSending = true;
      AppService.getInfo({
        from: this.from,
        to: this.to,
        type: this.type,
        search: this.search,
        no_card: true,
      }).then(
        (response) => {
          this.isSending = false;
          this.transactions = response.transactions.transactions;
          console.log(response);
        },
        (error) => {
          console.log(error);
          this.content = error;
        },
      );
    },
    changeDate(date) {
      console.log(date);
      if (date) {
        this.from = moment(date[0], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.to = moment(date[1], 'DD/MM/YYYY').format(
          'YYYY-MM-DD',
        );
        this.request();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.second-toolbar a {
  visibility: hidden;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
.modal-body {
  padding: 25px;
}
.popup-icons {
  height: 50px;
}
.popup-icons .stpe-icons-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  margin: auto;
}
.popup-icons .stpe-icons-center a.stpe-logo-banks {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #fff;
  overflow: hidden;
  cursor: default;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.popup-icons .stpe-icons-center a.stpe-logo-banks img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.stpe-component_dropdown .stpe-dropdown-area {
  display: none;
  position: absolute;
  top: 15px;
  left: 120px;
  width: 190px;
  margin-top: 4px;
  margin-left: -95px;
  padding-top: 12px;
  z-index: 2;
}
.drop-down-select-month:hover .stpe-dropdown-area{
  display: block;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box {
  background: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0,0,0,.15);
  -moz-box-shadow: 0 2px 20px rgba(0,0,0,.15);
  box-shadow: 0 2px 20px rgba(0,0,0,.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box .icon-caret-up {
  position: absolute;
  top: -5px;
  left: 120px;
  margin-left: -10px;
  color: #fff;
  font-size: 21px;
}
.stpe-dropdown-box ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li {
  float: none;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box.stpe-dropdown-text-center a {
  display: block;
  text-align: left;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li a {
  display: block;
  padding: 7px 18px;
  text-align: left;
  text-transform: none;
  color: #777;
  font-size: 14px;
  letter-spacing: 0;
  -webkit-transition: all .15s ease-out 0s;
  -moz-transition: all .15s ease-out 0s;
  -o-transition: all .15s ease-out 0s;
  transition: all .15s ease-out 0s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 99.9%;
  white-space: nowrap;
}
.stpe-dropdown-li {
  cursor: pointer;
}
.stpe-context_transactions-dialog form .input-row .drop-down-select-month a {
  font-size: 12px;
  line-height: 12px;
}
.stpe-dropdown-action {
  color: #18b272;
}
.stpe-invoice-active {
  background: #44e8a4;
}
.stpe-invoice-active a{
  color: #fff !important;
}
.mx-datepicker {
  width: 100% !important;
}
.alert-error {
  list-style: none;
  margin: 0px;
  padding: 15px;
}
.alert-error .alert {
  padding: 0;
  padding-left: 0px;
  padding-left: 8px;
  font-size: 13px;
  margin-bottom: 10px;
}
.item-search-select:hover {
  background: #eee;
  cursor: pointer;
}
</style>
