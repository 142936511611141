<template>
  <div>
    <div class="page-heading relative">
      <h2 class="no-margin">Importar</h2>
      <p style="color: #A5B2B9;">Solicite no seu banco o extrato no formato .ofx para importar na nossa plataforma.</p>
      <div class="header-line" style="margin-top: 40px;"></div>
      <a class="btn btn-default pull-right ml-5 inherit btn-heading" type="button" @click="$router.go(-1)"><span><i
            class="mdi mdi-arrow-left"></i></span> Voltar</a>
    </div>
    <div class="row">
      <div class="col-sm-12" style="padding-left: 0;">
        <div class="form-row hide-m">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <label>Escolha uma conta</label>
          </div>
        </div>
        <div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 p-right-m-none">
            <select-input v-model="account" :reduce="(account) => account.id" :options="wallets" label="name" :clearable="true" />
          </div>
        </div>
      </div>
    </div>
    <div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6" style="margin-top: 5px;">
        <div class="card no-shadow card-tab" style="border-radius: 10px !important;">
          <div class="card-body relative">
            <div class="loading-overlay-inside" v-if="loading" style="background-color:rgba(298, 294, 290, 0.9);">
              <div class="loader-box">
                <div class="circle-loader" style="top: 10%;"></div>
              </div>
            </div>
            <div class="dropz" id="dropz">
              <input name="ofx-file" type="file" id="file-ofx" data-unic="true" ref="ofxFile" @change="handleFiles" accept=".ofx" isfile="true" isunic="true">
              <p class="zone-text">Arraste aqui ou clique <br>para importar o arquivo .ofx</p>
            </div>
            <button class="btn btn-primary w-100 btn-import" @click="importFile"><i class="fa fa-upload"></i>Importar</button>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6" style="margin-top: 5px;">
        <div class="card no-shadow card-tab" style="border-radius: 10px !important;">
          <div class="card-body" style="padding-left: 15px;">
            <div class="transaction-amount">
              <div class="transaction-amount-item">
                <div class="transaction-icon">
                  <i class="mdi mdi-checkbox-blank-circle text-primary"></i>
                </div>
                <div class="transaction-info">
                  <strong>{{ totalIncome | currency }}</strong>
                  <span>Entradas</span>
                </div>
              </div>
              <!-- item -->
              <div class="transaction-amount-item">
                <div class="transaction-icon">
                  <i class="mdi mdi-checkbox-blank-circle text-danger"></i>
                </div>
                <div class="transaction-info">
                  <strong>{{ totalExpense | currency }}</strong>
                  <span>Saídas</span>
                </div>
              </div>
              <!-- item -->
              <div class="transaction-amount-item">
                <div class="transaction-icon">
                  <i class="mdi mdi-checkbox-blank-circle text-info"></i>
                </div>
                <div class="transaction-info">
                  <strong>{{ totalMoviment | currency }}</strong>
                  <span>Saldo Final</span>
                </div>
              </div>
            </div>

            <div class="transaction-visual">
              <v-chart class="chart" :option="option" />
            </div>
            <div style="clear:both"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12"><hr></div>
      <div class="col-md-12" v-if="transactions.length > 0" style="margin-bottom: 10px;">
        {{ period }}
        <button class="btn btn-primary to-right" @click="conciliarTodos">Conciliar Seleciondados</button>
        <button class="btn btn-danger to-right" style="margin-right: 5px;" @click="removeItens()"><i class="fa fa-trash"></i></button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card no-shadow card-tab" style="border-radius: 10px !important;">
          
          <div class="card-body np-top">
            <div class="row" v-if="transactions.length > 0">
              <div class="col-md-5 col-lg-5 col-sm-5 col-sm-5">
                <h3 class="conciliacao-title" style="margin-top: 0px;">
                  <input type="checkbox" class="to-left" id="is_all_conci" @change="selectAll">
                  <label style="margin-top: 4px;" for="is_all_conci" class="to-left"></label> Extrato do banco
                </h3>
              </div>
              <div class="col-md-2 col-lg-2 col-sm-2 col-sm-2"></div>

              <div class="col-md-5 col-lg-5 col-sm-5 col-sm-5">
                <h3 class="conciliacao-title" style="margin-top: 0px;">Lançamentos no Stimper</h3>
              </div>
            </div>

            <div class="mt-10" v-if="transactions.length > 0">
              <div class="row" v-for="(item, i) in transactions" :key="i" style="margin-bottom: 15px;">
                <div class="col-sm-5">
                  <div class="rounded shadow-card bg-white p10 rounded-transaction">
                    <div class="row">
                      <div class="col-sm-1 col-lg-1 col-md-1" v-if="!item.concilied">
                        <input style="display: block;" type="checkbox" @click="selectItem(item, key)"
                          v-model="item.selected">
                      </div>
                      <div class="col-sm-9 col-lg-9 col-md-9">
                        <h3 class="conciliacao-card-title">{{ item.MEMO | subofx }}</h3>
                      </div>
                      <div class="col-sm-2 col-lg-2 col-md-2 text-right icon-transaction-remove">
                        <i class="fa fa-trash" @click="removeItem(i)"></i>
                      </div>
                      <div class="col-sm-12">
                        <hr class="conciliacao-card-hr">
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <label>Valor</label>
                        <div class="block">
                          <span class="text-danger conciliacao-card-value" v-if="item.TRNTYPE == 'DEBIT'">-</span>
                          <span class="text-primary conciliacao-card-value" v-if="item.TRNTYPE == 'CREDIT'">+</span>
                          <span class="text-danger conciliacao-card-value" v-if="item.TRNTYPE == 'DEBIT'">{{ item.TRNAMT | currency }}</span>
                          <span class="text-primary conciliacao-card-value" v-if="item.TRNTYPE == 'CREDIT'">{{ item.TRNAMT | currency }}</span>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <label>Data</label>
                        <span class="block conciliacao-card-value">{{ item.DTPOSTED }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-2 text-center">
                  <button class="btn btn-primary mt-40" :disabled="item.sending" @click="send(i, item)"><i class="fa fa-check"></i> <span v-if="!item.concilied">Conciliar</span></button>
                </div>
                <div class="col-sm-5" v-if="!item.moviment">
                  <div class="rounded shadow-card bg-white p10 rounded-transaction">
                    <div class="row">
                      <div class="col-sm-12 col-lg-12 col-md-12" style="margin-bottom: 10px;">
                        <ul class="nav nav-tabs">
                          <li :id="`nav-n-btn-${i}`" :class="`active pointer nav-btn btn-line-${i}`"
                            @click="changeTab('#nav-n-btn-'+i, '#newMoviment-'+i, i, false, false)">
                            <a>Novo</a></li>
                          <li :id="`nav-s-btn-${i}`" :class="`nav-btn pointer btn-line-${i}`"
                            @click="changeTab('#nav-s-btn-'+i, '#searchMoviment-'+i, i, true, false)">
                            <a>Buscar</a></li>
                        </ul>
                      </div>
                      <div class="tab-content">
                        <div :id="`searchMoviment-${i}`" :class="`tab-pane fade in hide tab-line-${i}`">
                          <div class="col-sm-12 col-lg-12 col-md-12 relative">
                            <input class="form-control" placeholder="Pesquisar lançamentos" type="text" @click="searchTransaction('search-transaction', i)">
                            <div class="btn-search-input" style="top: 8px;" @click="searchTransaction('search-transaction', i)">
                              <i class="fas fa-search"></i>
                            </div>
                          </div>
                        </div>
                        <div :id="`newMoviment-${i}`" :class="`tab-pane fade in active tab-line-${i}`">
                          <div class="col-sm-6 col-lg-6 col-md-6">
                            <label><span>Título</span></label>
                            <input class="form-control" placeholder="Título" v-model="item.new.MEMO">
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <label><span>Categoria</span></label>
                            <select-input label="name" :reduce="(field) => field.id" v-model="item.new.CATEGORY"
                              :options="categories" :clearable="true" />
                          </div>
                          <div class="col-sm-12">
                            <hr class="conciliacao-card-hr">
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <label>Valor</label>
                            <money v-model="item.new.TRNAMT" v-bind="money" class="form-control"></money>
                          </div>
                          <div class="col-sm-6 col-md-6 col-lg-6">
                            <label>Data</label>
                            <date-picker v-model="item.new.DTPOSTED" :lang="lang"  value-type="format" format="DD/MM/YYYY" :input-class="`form-control`"></date-picker>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5" v-if="item.editing">
                  <div class="rounded shadow-card bg-white p10 rounded-transaction">
                    <div class="row">
                      <div class="col-sm-10 col-lg-10 col-md-10">
                      </div>
                      <div class="col-sm-2 col-lg-2 col-md-2 text-right icon-transaction-remove">
                        <i class="fa fa-times" @click="editRemove(i)"></i>
                      </div>
                      <div class="col-sm-12">
                        <hr class="conciliacao-card-hr">
                      </div>
                      <div class="col-sm-6 col-lg-6 col-md-6">
                        <label><span>Título</span></label>
                        <input class="form-control" placeholder="Título" v-model="item.moviment.title">
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <label><span>Categoria</span></label>
                        <select-input label="name" :reduce="(field) => field.id" v-model="item.moviment.category"
                          :options="categories" :clearable="true" />
                      </div>
                      <div class="col-sm-12">
                        <hr class="conciliacao-card-hr">
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <label>Valor</label>
                        <money v-model="item.moviment.amount" v-bind="money" class="form-control"></money>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <label>Data</label>
                        <date-picker v-model="item.moviment.date" :lang="lang"  value-type="format" format="DD/MM/YYYY" @change="changeDate" :input-class="`form-control`"></date-picker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5" v-if="item.moviment && !item.editing">
                  <div class="rounded shadow-card bg-white p10 rounded-transaction"
                    :class="[item.TRNTYPE == 'CREDIT' ? 'card-income' : '', item.TRNTYPE == 'DEBIT' ? 'card-expense': '']">
                    <div class="row">
                      <div class="col-sm-10 col-lg-10 col-md-10">
                        <h3 class="conciliacao-card-title">{{ item.moviment.title | subofx }}</h3>
                      </div>
                      <div class="col-sm-2 col-lg-2 col-md-2 text-right icon-transaction-remove">
                        <i class="fa fa-edit" @click="editing(i)"></i>
                        <i class="fa fa-unlink m-left-8" @click="unlink(i, item)"></i>
                      </div>
                      <div class="col-sm-12">
                        <hr class="conciliacao-card-hr">
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <label>Valor</label>
                        <div class="block">
                          <span class="text-danger conciliacao-card-value" v-if="item.TRNTYPE == 'DEBIT'">-</span>
                          <span class="text-primary conciliacao-card-value" v-if="item.TRNTYPE == 'CREDIT'">+</span>
                          <span class="text-danger conciliacao-card-value" v-if="item.TRNTYPE == 'DEBIT'">{{ item.moviment.amount | currency }}</span>
                          <span class="text-primary conciliacao-card-value" v-if="item.TRNTYPE == 'CREDIT'">{{ item.moviment.amount | currency }}</span>
                        </div>
                      </div>
                      <div class="col-sm-6 col-md-6 col-lg-6">
                        <label>Data</label>
                        <span class="block conciliacao-card-value">{{ item.moviment.date | moment("DD/MM/YYYY") }}</span>
                      </div>
                      <div class="col-sm-12">
                        <div class="alert alert-warning no-margin alert-moviment transaction-alert-info">Lançamento encontrado no sistema</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-center ng-scope" v-if="transactions.length == 0">
              <img src="/assets/images/bank-transfer.png " class="nothing-image">
              <h3 class="nothing-text">Nenhuma transação importada.</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>
    <search-transaction-modal id="search-transaction"/>
  </div>
</template>

<script>
// @ is an alias to /src
// @ is an alias to /src
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import VChart, { THEME_KEY } from "vue-echarts";
import SelectInput from '@/components/SelectInput.vue';
import $ from 'jquery';
import AppService from '@/services/app.service';
import pace from 'pace-js';
import SearchTransactionModal from '@/components/SearchTransactionModal.vue';
import moment from 'moment-timezone';
import Swal from 'sweetalert2';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
import {ptBR} from 'vuejs-datepicker/dist/locale';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  name: 'Home',
  components: {
    VChart,
    SelectInput,
    SearchTransactionModal,
    DatePicker,
  },
  computed: {
    classes() {
      return ['wizard'];
    },
    info() {
      return JSON.parse(localStorage.getItem('stimper_user'));
    },
  },
  data() {
    return {
      id: '#dropz',
      ptBR: ptBR,
      lang: {
        formatLocale: {
          // MMMM
          months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
          // MMM
          monthsShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Otu', 'Nov', 'Dez'],
          // dddd
          weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
          // ddd
          weekdaysShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
          // dd
          weekdaysMin: ['Do', 'Se', 'Te', 'Qua', 'Qui', 'Sex', 'Sa'],
        },
        yearFormat: 'YYYY',
        monthBeforeYear: true,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      accpetType: "",
      globalFiles: [],
      isFile: true,
      isUnic: true,
      option: {
        tooltip: {
          backgroundColor: "#fff",
          /*formatter: '<h2 class="color-white no-margin">{a0}</h2>{c0}<br> {b0}'*/
          formatter: function (params) {
            var template =
              '<span class="text-left"><h3 class="color-black no-margin text-left">' +
              params.name +
              " (" +
              params.percent +
              "%)</span></span>";

            return template;
          },
        },
        color: ["#57BE65", "#ff1a1a", "#3EA4F1"],
        series: [
          {
            name: "R$",
            type: "pie",
            radius: ["50%", "70%"],
            itemStyle: {
              borderRadius: 5,
              borderColor: "#fff",
              borderWidth: 1,
            },
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: "center",
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: "16",
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [],
          },
        ],
      },
      wallets: [],
      account: null,
      loading: false,
      transactions: [],
      transactionsCount: 0,
      countMoviment: 0,
      totalIncome: 0,
      countIncome: 0,
      totalExpense: 0,
      countExpense: 0,
      totalMoviment: 0,
      itensSelected: [],
      period: '',
      categories: [],
    };
  },
  provide: {
    [THEME_KEY]: "light",
  },
  mounted() {
    window.scrollTo(0, 0);
    console.log('Carteira');
    console.log(this.$route.params);
  },
  created() {
    pace.start();
    //Default options
    var _defaultOptions = {
      zoneText: "<p class='zone-text'>Drop files here or click.</p>",
    };
    //Init default options
    var _zoneText = _defaultOptions.zoneText;

    //Append defult items
    $(this.id).append(_zoneText);

    var input = $('#file-ofx');
    if (input.attr("type") == "file") {
      input.on("change", this.handleFiles);
    }

    if (input.attr("isFile") == "true") {
      this.isFile = true;
    }

    if (input.attr("isUnic") == "true") {
      this.isUnic = true;
    }

    $(document).delegate(".p-remove", "click", function () {
      var i = $(this).attr("i-file");
      this.globalFiles.splice(i, 1);

      this.renderImages();

      if (this.globalFiles.length == 0) {
        $(this.id).append(_zoneText);
      }
    });

    AppService.getWallets({}).then(
      (response) => {
        this.isLoadingWallet = false;
        this.wallets = response.wallets;
        console.log(response);
      },
      (error) => {
        console.log(error);
        this.content = error;
      },
    );

    this.$root.$on('transaction.select', this.chnageItem);
  },
  methods: {
    changeDate() {
      this.$forceUpdate();
    },
    send(key, item){
      this.transactions[key].sending = true;
      this.transactions[key].concilied = true;
      this.finishImport(item, false, key);
    },
    editRemove(index) {
      this.transactions[index].editing = false;
      this.$forceUpdate();
    },
    editing(index) {
      this.transactions[index].editing = true;
      this.$forceUpdate();
    },
    unlink(index, item) {
      this.transactions[index].moviment = null;
      this.transactions[index].new = item;
      this.$forceUpdate();
    },
    finishImport(item, selected, index) {
      console.log(index);
      let itensPush = [];
      if(this.transactions.length == 0) { 
        this.$toast.show({
          title: "Erro",
          content: "Você precisa importar antes de finalizar",
          type: "error",
        });
        return;
      }
      if(this.account == null || this.account == "") {
        this.$toast.show({
          title: "Erro",
          content: "Você precisa informar uma conta",
          type: "error",
        });
        return;
      }

      let hasCategory = true; 
      if(selected) {
        if(this.transactions.length > 0) {
            for(var i =0; i < this.transactions.length; i++) {
                if(this.transactions[i].CATEGORY == null || this.transactions[i].CATEGORY == undefined) {
                  hasCategory = false;
                  break;
                }
            }   
        }
        this.transactions.forEach(function (item) {
          if(item.selected) {
            itensPush.push(item)
          }
        });
      }
      if(!selected) {
        if(item.CATEGORY == null || item.CATEGORY == undefined) {
          hasCategory = false;
        }
        itensPush.push(item);
      }
      if(!hasCategory) {
        this.$toast.show({
          title: "Erro",
          content: "Todos os itens precisam de uma categoria",
          type: "error",
        });
        return;
      }
      console.log( itensPush );
      console.log( JSON.stringify(this.itensSelected) );
      //let newSelected = this.transactions;

      AppService.finishImport({
        account: this.account,
        moviments: itensPush,
      }).then(
        (response) => {
          this.$toast.show({
            title: "Sucesso",
            content: "Itens importado",
            type: "success",
          });
          console.log('Finish Import');
          console.log(response);
          this.transactions.forEach(function(item) {
            if(item.selected) {
              item.concilied = true;
              item.sending = true;
            }
          });
          this.$forceUpdate();
        },
        (error) => {
          console.log(error);
        },
      );
    },
    removeItens() {
      Swal.fire({
        title: `Remover transação?`,
        text: 'Tem certeza que deseja remover as transações?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          if(this.transactions.length > 0) {
            let array = this.transactions.filter((item) => {
              return item.selected !== true;
            });
            this.transactions = array;
          }
        }
      });
      //this.calculeItens();
    },
    conciliarTodos() {
      Swal.fire({
        title: `Conciliar transação?`,
        text: 'Tem certeza que deseja conciliar todas as transações?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          this.finishImport('', true, '');
        }
      });
      //this.calculeItens();
    },
    chnageItem(selected) {
      console.log(selected);
      this.transactions[selected.index].editing = false;
      this.transactions[selected.index].moviment = {
        id: selected.item.id,
        CATEGORY: selected.item.category.id,
        title: selected.item.title,
        amount: selected.item.amount
      };
      if(selected.item.transaction_date) {
        this.transactions[selected.index].moviment.date = moment(selected.item.transaction_date).format("DD/MM/YYYY")
      }
      this.$forceUpdate();
      console.log(this.transactions);
    },
    searchTransaction(id, i) {
      let data  = {
        index: i,
      };
      this.$root.$emit('show.modal', id, data);
    },
    changeTab(btn, tab, tabLine, isNew, isEdit) {
      $('.tab-line-'+tabLine).removeClass('active');
      $('.tab-line-'+tabLine).removeClass('hide');
      $(tab).addClass('active');

      $('.btn-line-'+tabLine).removeClass('active');
      $(btn).addClass('active');

      this.transactions[tabLine].isEdit = isEdit;
      this.transactions[tabLine].isNew  = isNew;
      return false;
    },
    selectAll() {
      if (!this.isSelectedAll) {
        this.transactions.forEach(function (item) {
          item.selected = true;
        });
      } else {
        this.transactions.forEach(function (item) {
          item.selected = false;
        });
      }
      console.log(this.transactions);
      this.isSelectedAll = !this.isSelectedAll;
      this.$forceUpdate();
    },
    selectItem(item, index) {
      console.log(index);
      if (item.selected) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    },
    removeItem(i) {
      this.transactions.splice(i, 1);
      this.calculeItens();
    },
    calculeItens() {
      this.totalIncome = 0;
      this.countIncome = 0;
      this.totalExpense = 0;
      this.countExpense = 0;
      this.totalMoviment = 0;
      this.countMoviment = 0;
      this.transactionsCount = 0;
      this.option.series[0].data = [];

      if (this.transactions.length > 0) {
        this.period = this.transactions[0].DTPOSTED + ' a ' + this.transactions[this.transactions.length - 1].DTPOSTED

        this.transactions.forEach((item) => {
          this.updateDataInfo(item);
        });
      }
      this.totalMoviment = this.totalIncome - this.totalExpense;
      this.countMoviment = this.countIncome + this.countExpense;

      this.option.series[0].data.push({
        value: this.totalIncome, name: "Receitas",
      });
      this.option.series[0].data.push({
        value: this.totalExpense, name: "Despesas",
      });
      this.option.series[0].data.push({
        value: this.totalMoviment, name: "Saldo Final"
      });

      this.$forceUpdate();
    },
    updateDataInfo(item) {
      this.transactionsCount++;
      if (item.TRNTYPE == 'DEBIT') {
        this.totalExpense += parseFloat(item.TRNAMT);
        this.countExpense++;
      }
      if (item.TRNTYPE == 'CREDIT') {
        this.totalIncome += parseFloat(item.TRNAMT);
        this.countIncome++;
      }
    },
    importFile() {
      var formData = new FormData();
      formData.append('ofx-file', this.$refs.ofxFile.files[0]);
      formData.append('account_id', this.account);
      formData.append('check_sub', true);
      if (this.account == null) {
        this.$toast.show({
          title: "Erro",
          content: "Escolha uma conta antes de enviar",
          type: "error",
        });
        return;
      }
      pace.start();
      this.loading = true;
      AppService.import(formData).then(
        (response) => {
          if (response.status == 301) {
            this.$router.push({ name: 'Premium', params: { expire: true }});
          }
          this.transactions = response.ofx_json;
          this.categories = response.categories;
          this.loading = false;
          this.calculeItens();
        },
        (error) => {
          console.log(error);
        },
      );
    },
    handleFiles(event) {
      var files = event.currentTarget.files;
      this.accpetType = event.currentTarget.accept;
      if (this.isUnic) {
        this.globalFiles = [];
      }
      for (let i = 0; i < files.length; i++) {
        this.globalFiles.push(files[i]);
      }
      this.renderImages();
    },
    renderImages() {
      $(this.id).find(".zone-text").remove();
      $(this.id).find(".drop-img").remove();
      for (let i = 0; i < this.globalFiles.length; i++) {
        const file = this.globalFiles[i];

        //if (!file.type.startsWith('image/')){ continue }

        const dropImg = document.createElement("div");
        dropImg.classList.add("drop-img");

        if (this.isUnic) {
          dropImg.classList.add("file-unic");
        }

        const h3 = document.createElement("h3");
        h3.classList.add("h3-type");
        if (file.type == null || file.type == "") {
          h3.innerHTML = this.accpetType;
        } else {
          h3.innerHTML = file.type;
        }

        dropImg.append(h3);

        //element remove
        const remove = document.createElement("span");
        if (!this.isUnic) {
          remove.innerHTML = "×";
          remove.classList.add("p-remove");
          remove.setAttribute("i-file", i);
        }

        //Img
        const img = document.createElement("img");
        img.file = file;

        dropImg.append(remove);
        dropImg.append(img);
        $(this.id).append(dropImg);

        const reader = new FileReader();
        reader.onload = (function () {
          return function () {
          };
        })(img);
        reader.readAsDataURL(file);
      }

      this.updateInputInfos();
    },
    updateInputInfos() {
      var dataTransfer = new DataTransfer();
      for (let i = 0; i < this.globalFiles.length; i++) {
        dataTransfer.items.add(this.globalFiles[i]);
      }
      $(this.id).find(":input")[0].files = dataTransfer.files;
    },
    request() {
    },
    print() {
      window.print();
    },
  },
};
</script>

<style scoped lang="scss">
.cashflow-print {
  position: absolute;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.page-heading {
  margin-bottom: 0px;
}

.chart {
  height: 200px;
}

.chart-line {
  height: 350px;
  margin-top: 0px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.swiper-container {
  height: 500px;
}

.figure-stats {
  background-color: #F9F9F9;
  border: 1.2px solid #E7EAEE;
  margin-top: 0px;
}

.card-header {
  border-bottom: none;
}

.figure-section {
  padding-top: 10px;
  padding-bottom: 10px;
}

.heading-title {
  padding-left: 0px;
}

.total-amount {
  margin-top: 10px;
  display: block;
}

.btn-remove-filter {
  height: 35px !important;
  border-radius: 50%;
  width: 35px;
  padding: 0px !important;
  text-align: center;
}

.btn-remove-filter i {
  margin-right: 0;
}

.dropz {
  background: #fafafa;
  border-radius: 5px;
  border: 1px dashed rgb(183, 188, 192);
  border-image: none;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-height: 200px;
  overflow: hidden;
  cursor: pointer;
}

.dropz input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0;
  cursor: pointer;
}

.dropz p {
  text-align: center;
  padding-top: 15%;
  font-size: 20px;
  color: #ccc;
}


.drop-img .p-remove {
  position: absolute;
  color: #fff;
  right: 0;
  display: block;
  text-align: center;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  background: #ec2d38;
}

.drop-img .p-remove:hover {
  cursor: pointer;
  background: #d52b35;
}

.drop-img img {
  width: 100%;
  min-height: 100%;
}

.file-unic {
  float: none !important;
  margin: 0 auto !important;
  margin-top: 10% !important;
}

.btn-import {
  margin-top: -30px;
  z-index: 2;
  margin-bottom: 0px;
  left: 10.6%;
  width: 79% !important;
  position: absolute;
  box-shadow: 1px -2px 3px 0px #18b27242;
}
.rounded-transaction {
  border: solid #eaeaea 1px !important;
  border-radius: 10px !important;
}
.icon-transaction-remove {
  color: #b9b9b9;
  cursor: pointer;
}
.icon-transaction-remove i:hover {
  color: #a7a7a7;
}
.conciliacao-card-title {
  color: #777;
  font-weight: 100;
}
.transaction-alert-info {
  margin-top: 10px !important;
  font-size: 13px;
  padding: 10px;
}
.mx-datepicker {
  width: 100%;
}
</style>
